<template>
    <div class="container-fluid">
        <section class="content-header">
                <div class="row mb-2">
                    <h3>{{ $t('general.listadousuarios') }}</h3>
                </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                    <!--<usuarios-listado-permisos />-->
                    <usuarios-listado-carga />
                </div>
                <div class="col-md-10">
                  <tabla-datos :columnas="columnas" :datos="usuarios" :filtros="filtros" :filtrosBuscador="filtrosBuscador"
                   :total="total" @quieroDatos="obtenerUsuarios($event); mievento = $event;" /> 
                </div>                
            </div>
        </section>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { PwgsApi } from './../../services/PwgsApi.js';
import TablaDatos from '../TablaDatos.vue';
//import UsuariosPagePermisos from './usuarios/listado/UsuariosPagePermisos.vue'
import UsuariosPageCarga from './usuarios/listado/UsuariosPageCarga.vue'
export default {
    name: "usuarios",
    components: {
        TablaDatos,
        //'usuarios-listado-permisos': UsuariosPagePermisos,
        'usuarios-listado-carga': UsuariosPageCarga,
    },
    data() {
        return {
            token:false,
            total: '',
            modificado: false,
            nombre:'',
            loading1: true,
            eliminado: false,
            usuarios: [],
            filtrosBuscador: ['nombre', 'nombre_completo'], // filtros para el buscador
          
            filtros: { // filtros de columna
                'nombre': { value: "", matchMode: FilterMatchMode.CONTAINS },
                'nombre_completo': { value: "", matchMode: FilterMatchMode.CONTAINS },
            },
            columnas: [
                {
                    header: 'Usuario',
                    field: 'nombre',
                    sortable: true,
                },
                {
                    header: 'Nombre',
                    field: 'nombre_completo',
                    sortable: true,
                },
              /*  {
                    header: 'Permisos',
                    data: null,
                },*/
                {
                    header: 'Carga',
                    field: 'carga',
                    class:'carga',
                },
                {
                    header: 'Ausente',
                    componente: {
                        ruta: 'components/pages/usuarios/UsuarioAusente.vue',
                        nombre: 'UsuarioAusente',
                        field: 'estado_usuario',
                        props: {
                            checked: (datos) => {
                                return datos.estado_usuario == 'ausente'
                            }
                        },
                        evento: 'cambioAusente',
                        listener: (valor) => {
                            alert('Acciones para poner el usuario: ' + (valor ? 'ausente' : 'no ausente'));
                        }
                        
                    }
                },
                {
                    header: 'Inicio sesión',
                    field: 'tiempo_inicio_sesion_usuario',
                    sortable: true, 
                    formato: this.formatearFecha,
                },
                {
                    header: 'Fin sesión',
                    field: 'tiempo_cierra_sesion_usuario',
                    sortable: true,
                    formato: this.formatearFecha,
                },
                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'idusuario',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                this.$router.push({ name: 'Usuario', params: { id: id }});
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'idusuario',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {                  
                                if (confirm('Desea eliminar el usuario? ' +id)) {
                                    this.eliminarUsuario(id);
                                }
                            }
                        },
                        {                            
                            nombre:'Cerrar sesión',
                            class: 'cerrar_sesion',
                            tipo: 'warning',
                            nombreId: 'idusuario',
                            icono: 'fas fa-power-off',
                            // Debe ser arrow function para que this sea este componente             
                            accion: (id) => {
                                this.cerrarSesion(id);
                            }
                        }
                    ]
                }
            ],
        }
    },
    methods: {
        refrescar() { 
            this.obtenerUsuarios();
        },
        esColumnaPersonalizada(col) {
            return col.formato || col.acciones || col.componente;
        },
        componenteDinamico(ruta) {
            console.log('importando dinÃ¡micamente componente ' + ruta);
            return defineAsyncComponent(() => import(`@/${ruta}`));
        },
        async obtenerUsuarios(event) {
            const api = new PwgsApi();
            console.log('evvvvn', event);
            
            // Crear una copia profunda de `event`
            var evaux = JSON.parse(JSON.stringify(event));
            
            for(let ev of Object.entries(evaux.filters)){
                if(ev[1].value === ''){
                    delete evaux.filters[ev[0]];
                }
            }

            console.log('evaux', evaux);
            
            const usuarios = await api.get('usuarios/tramitadores', evaux);
            this.usuarios = usuarios.datos;
            this.total = usuarios.n_total_registros; 
            this.eliminado = false;
            this.modificado = true; 
        },
        async cerrarSesion(id){
            const api = new PwgsApi();
            await api.put('logout',{id_usuario:id});
            this.obtenerUsuarios();
        },
        async eliminarUsuario(id) {
            /* delete  modulos/pwgsapi/index.php/usuarios/:id */
            const api = new PwgsApi();
            await api.delete('usuarios/' + id);
            this.eliminado = true;            
        },
        formatearFecha(fecha) {
            const fechaNueva = new Date(fecha * 1000);
            return fechaNueva.toLocaleDateString() + ' ' + fechaNueva.toLocaleTimeString({},{ hour: '2-digit', minute: '2-digit' });
        },   
        crear() {
            this.$router.push({ name: 'Crear Usuarios', params: { id: 0 } });
        },
    },
    mounted() {
       
        this.columnas[0].header = this.$t("general.usuario");
        this.columnas[1].header = this.$t("general.nombre"); 
        this.columnas[2].header = this.$t("general.carga");  
        this.columnas[3].header = this.$t("general.ausente");
        this.columnas[4].header = this.$t("general.iniciosesion");
        this.columnas[5].header = this.$t("general.finsesion");
        this.columnas[6].header = this.$t("general.acciones"); 
 
        this.loading1 = false;
    },
    watch: {
        eliminado() {
            this.obtenerUsuarios();
        },
        modificado() {
            this.obtenerUsuarios(); 
        },

    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
.pi-filter-icon{
    display: none !important;
}
.p-column-filter-menu-button.p-link{
    display: none !important;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active{
    display: none !important;
}
</style>